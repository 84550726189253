<template>
  <div class="col-lg-8">
    <div class="row">
      <div class="col-12">
        <div class="dash-pro-item mb-30 dashboard-widget">
          <div class="d-flex justify-content-between align-items-center">
            <h4 class="title">Informações pessoais</h4>
            <button
              type="submit"
              class="custom-button"
              id="edit-button"
              @click="onEdit()"
            >
              Editar
            </button>
            <!-- <span class="edit"><i class="flaticon-edit"></i> Editar</span> -->
          </div>
          <ul class="dash-pro-body">
            <li>
              <div class="info-name text-uppercase">Nome</div>
              <div class="info-value">
                {{ getUsuarioInfo.nomeCompleto }}
              </div>
            </li>
            <li>
              <div class="info-name text-uppercase">Nascimento</div>
              <div class="info-value">
                {{ getUsuarioInfo.dataNascimento | formatData }}
              </div>
            </li>
            <li>
              <div class="info-name text-uppercase">Celular</div>
              <div class="info-value">
                {{ getUsuarioInfo.telefone | formatTelefone }}
              </div>
            </li>
            <!-- <li>
              <div class="info-name text-uppercase">Telefone</div>
              <div class="info-value">
                {{ getUsuarioInfo.telefoneConvencional | formatTelefone }}
              </div>
            </li> -->
            <li v-if="getUsuarioInfo.endereco">
              <div class="info-name text-uppercase">Endereço</div>
              <div class="info-value">
                {{ getUsuarioInfo.endereco.logradouro }}
                {{ getUsuarioInfo.endereco.numero }}
                {{ getUsuarioInfo.endereco.complemento }} -

                {{ getUsuarioInfo.endereco.bairro }},
                {{ getUsuarioInfo.endereco.cidade }} -
                {{ getUsuarioInfo.endereco.estado }} - CEP:
                {{ getUsuarioInfo.endereco.cep }}
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div class="col-12">
        <div class="dash-pro-item mb-30 dashboard-widget">
          <div class="header">
            <h4 class="title">Documentos</h4>
            <!-- <span class="edit"><i class="flaticon-edit"></i> Editar</span> -->
          </div>
          <ul class="dash-pro-body">
            <li v-for="doc in getUsuarioInfo.documentos" :key="doc.documentoId">
              <div
                v-if="['CPF', 'CNPJ'].includes(doc.tipo)"
                class="info-name text-uppercase"
              >
                {{ doc.tipo }}
              </div>
              <div v-if="['CPF', 'CNPJ'].includes(doc.tipo)" class="info-value">
                {{ doc.numeroDocumento }}
              </div>

              <div v-if="doc.tipo == 'RG'" class="info-name text-uppercase">
                {{ doc.tipo }}
              </div>
              <div v-if="doc.tipo == 'RG'" class="info-value">
                {{ doc.numeroDocumento }}
              </div>
              <div v-if="doc.tipo == 'RG'" class="info-name text-uppercase">
                EMISSÃO
              </div>
              <div v-if="doc.tipo == 'RG'" class="info-value">
                {{ doc.dataEmissao | formatData }}
              </div>
              <div v-if="doc.tipo == 'RG'" class="info-name text-uppercase">
                EMISSOR
              </div>
              <div v-if="doc.tipo == 'RG'" class="info-value">
                {{ doc.orgaoEmissor }}
              </div>
            </li>
            <li></li>
          </ul>
        </div>
      </div>
      <div class="col-12">
        <div class="dash-pro-item mb-30 dashboard-widget">
          <div class="header">
            <h4 class="title">Conta</h4>
            <!-- <span class="edit"><i class="flaticon-edit"></i> Editar</span> -->
          </div>
          <ul class="dash-pro-body">
            <li>
              <div class="info-name text-uppercase">Email</div>
              <div class="info-value">{{ getUsuarioInfo.email }}</div>
            </li>
            <!-- <li>
              <div class="info-name text-uppercase">Senha</div>
              <div class="info-value">xxxxxxxxxxxxxxxx</div>
            </li> -->
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
export default {
  name: 'Perfil',
  data() {
    return {
      colorPrimary: process.env.VUE_APP_PRIMARY_COLOR,
      colorSecondary: process.env.VUE_APP_SECONDARY_COLOR,
      colorConstrast: process.env.VUE_APP_CONSTRAST_COLOR,
    };
  },
  computed: {
    ...mapGetters(['getUsuarioInfo']),
  },
  methods: {
    ...mapActions(['info']),

    onEdit() {
      this.$router.replace('edit-perfil');
    },
  },
  created() {
    this.info();
  },
  filters: {
    formatData(data) {
      if (data) return data.substring(0, 10).split('-').reverse().join('/');
    },
    formatTelefone(tel) {
      if (!tel || tel.length < 11) return '';

      if (typeof tel !== 'string') tel = tel.toString();

      tel = tel.replace(/\D/g, '');

      return tel.replace(/(\d{2})(\d{5})(\d{4})/, '($1) $2-$3');
    },
  },
};
</script>

<style>
#edit-button {
  height: 50px;
  font-size: 0.9rem;
  background-color: v-bind(colorPrimary);
  color: v-bind(colorConstrast);
}
</style>
